<template>
  <div class="product-form" style="position: relative;">
    <div v-if="!loading">
      <p
        style="width: 100%; text-align: center; font-weight: 700; font-size: 25px; margin: 20px auto 40px auto;"
      >
        {{
          itemId != "" && itemId != null
            ? $store.getters["recipesConfig/titles"].editingItem
            : $store.getters["recipesConfig/titles"].itemAdditon
        }}
      </p>

      <div class="product-info" v-if="tabs[0].selected">
        <inputField
          v-for="(property_, index) in itemInputs"
          :data-v-step="index"
          v-if="property_.optional == null"
          :key="index"
          :inputFor="property_.inputFor"
          :inputType="property_.type"
          :optionsUrl="property_.optionsUrl"
          :title="property_.title"
          :inputIsRequired="property_.isRequired"
          :inputIsInteger="property_.isInteger"
          :maxlength="property_.maxlength"
          :value="item[property_.name]"
          v-model="item[property_.name]"
        >
        </inputField>

        <optionalInput
          v-for="(property_, index) in itemInputs"
          v-if="property_.optional"
          :data-v-step="index"
          :key="index"
          :name="property_.name"
          :enabled="!!item[property_.name]"
          @usageState="
            payload => {
              item[payload.name] = payload.state ? item[payload.name] : null;
            }
          "
          :header="property_.header"
        >
          <template v-slot:body>
            <dateInptu
              v-if="property_.type === 'date'"
              :title="property_.title"
              :value="item[property_.name]"
              v-model="item[property_.name]"
            >
            </dateInptu>

            <inputField
              v-else
              :key="index"
              :inputType="property_.type"
              :title="property_.title"
              :optionsUrl="property_.optionsUrl"
              :inputIsRequired="property_.isRequired"
              :inputIsInteger="property_.isInteger"
              :value="item[property_.name]"
              v-model="item[property_.name]"
            >
            </inputField>
          </template>
        </optionalInput>

        <div style="margin: 20px auto">
          <itemCard
            :image="item.image"
            :price="item.price"
            :name="item.name"
            :description="item.description"
          ></itemCard>
        </div>
      </div>

      <div class="variations-info" v-else></div>

      <div id="product-controll">
        <button class="mujeeb-button green big" @click="submitNewItem">
          {{ itemId ? "تعديل" : "إضافة" }}
        </button>
        <button class="mujeeb-button big" @click="$router.go(-1)">عودة</button>
      </div>
    </div>
    <loading-spinner
      v-else
      position="relative"
      cssStyle="height: calc(100vh - 50px);"
    ></loading-spinner>
    <v-tour
      name="product_tour"
      :steps="tourSteps"
      :options="tourOptions"
      :callbacks="tourCallbacks"
    ></v-tour>
  </div>
</template>

<script>
import inputField from "../../input/GenericInputField";
import dateInptu from "../../input/DateInput";
import optionalInput from "../../input/OptionalInput";
import itemCard from "./../utilityComponents/itemCard";
import { EventBus } from "../../../utils/EventBus";

import { createNamespacedHelpers } from "vuex";
const { mapGetters } = createNamespacedHelpers("recipesConfig");

function isInteger(condition) {
  return value => {
    return condition ? /^[0-9\u0660-\u0669]+$/.test(value) : true;
  };
}

const isRequired = inputType => value => {
  switch (inputType) {
    case "tags":
    case "categories":
    case "multi-select":
      return value != null ? value.length !== 0 : false;
    default:
      return value != null ? value !== "" : false;
  }
  // return condition ? inputType === 'tags' ? value.length !== 0 : value !== '' : true
};

export default {
  name: "product-settings",
  components: {
    inputField,
    dateInptu,
    optionalInput,
    itemCard
  },
  data() {
    return {
      tabs: [
        {
          title: "المنتج",
          icon: "plus",
          selected: true,
          name: "item"
        },
        {
          title: "اختلافات",
          icon: "shapes",
          selected: false,
          name: "extras"
        }
      ],
      itemId: null,
      loading: false,
      previousCategories: [],
      tourOptions: {
        labels: {
          buttonSkip: "إنهاء",
          buttonPrevious: "السابق",
          buttonNext: "التالي",
          buttonStop: "إنهاء"
        }
      },
      item: {
        id: ""
      },
      tourCallbacks: {
        onStop: this.stopShowingTour
      },
      numberRegex: /^[0-9\u0660-\u0669]+$/g,
      messages: {
        services: {
          error: "حدث خطأ أثناء إدخال الخدمة",
          success: "تمت إضافة الخدمة بنجاح"
        },
        store: {
          error: "حدث خطأ أثناء إدخال المنتج",
          success: "تمت إضافة المنتج بنجاح"
        },
        restaurant: {
          error: "حدث خطأ أثناء إدخال المنتج",
          success: "تمت إضافة المنتج بنجاح"
        }
      }
    };
  },
  methods: {
    onTabChanged(index) {
      this.tabs.map((option, i) => {
        if (i === index) {
          option.selected = true;
        } else {
          option.selected = false;
        }
      });
    },
    submitNewItem() {
      // send an ITEM_SUBMIT event to the every inputField component
      EventBus.$emit("SUBMIT_ITEM");
      if (!this.$v.$invalid) {
        const options = {
          method: "post",
          url: `/services/chatbot/pages/${this.$store.getters["page/id"]}/users/${this.$store.getters["user/id"]}/items/template/${this.$store.getters["page/botRecipe"]}/item`,

          responseType: "json",
          data: this.finalItem
        };
        if (this.itemId) {
          options.method = "put";
          options.url = `/services/chatbot/pages/${this.$store.getters["page/id"]}/users/${this.$store.getters["user/id"]}/items/template/${this.$store.getters["page/botRecipe"]}/${this.itemId}`;
          options.data.previousCategories = this.previousCategories;
        }
        this.loading = true;
        this.$api
          .customRequest(options)
          .then(({ data }) => {
            this.$buefy.toast.open({
              duration: 3000,
              message: this.messages[this.$store.getters["page/botRecipe"]].success,
              position: "is-top",
              type: "is-success"
            });
            this.$router.go(-1);
          })
          .catch(error => {
            console.log(error);
            this.$buefy.toast.open({
              duration: 3000,
              message: this.messages[this.$store.getters["page/botRecipe"]].error,
              position: "is-top",
              type: "is-danger"
            });
          })
          .finally(() => {
            this.loading = false;
          });
      } else if (this.$v.item.image.$invalid) {
        this.$buefy.toast.open({
          duration: 3000,
          message: "يجب إدخال صورة",
          position: "is-top",
          type: "is-danger"
        });
      } else {
        this.$buefy.toast.open({
          duration: 3000,
          message: "يجب تعبئة الحقول الناقصة",
          position: "is-top",
          type: "is-danger"
        });
      }
    },
    stopShowingTour() {
      this.$buefy.dialog.confirm({
        title: `إخفاء الخطوات التعليمية`,
        message: `هل تود إيقاف إظهار الخطوات التعليمية في المرات القادمة؟`,
        confirmText: "إيقاف",
        cancelText: "كلا",
        type: "is-success",
        onConfirm: () => {
          // TODO make request to add the recipe to this pages
          this.$store.dispatch("page/stopShowingTour", "item");
        }
      });
    }
  },
  computed: {
    tourSteps() {
      return this.itemInputs.reduce((acc, item, index) => {
        const tmp = {};
        tmp.content = item.content;
        tmp.target = `[data-v-step="${index}"]`;
        tmp.params = {
          placement: "bottom",
          enableScrolling: false
        };
        acc.push(tmp);
        return acc;
      }, []);
    },
    finalItem() {
      return Object.keys(this.item).reduce((acc, property) => {
        // TODO check the properties of the item's input
        switch (typeof this.item[property]) {
          case "boolean":
            if (this.item[property] != null) {
              acc[property] = this.item[property];
            }
            break;
          case "string":
            if (this.item[property] != null && this.item[property]) {
              acc[property] = this.item[property];
            } else {
              acc[property] = null;
            }
            break;
          case "object":
            if (Array.isArray(this.item[property])) {
              if (this.item[property].length !== 0) {
                acc[property] = this.item[property];
              }
            } else {
              if (this.item[property] == null) {
                acc[property] = null;
              } else if (Object.keys(this.item[property]).length !== 0) {
                acc[property] = this.item[property];
              }
            }
            break;
          case "number":
            if (this.item[property] != null && this.item[property]) {
              acc[property] = this.item[property];
            } else {
              acc[property] = null;
            }
            break;
        }
        return acc;
      }, {});
    },
    optional_properties_validity_state() {
      let validityState = true;
      for (let index = 0; index < this.itemInputs.length; index++) {
        const input = this.itemInputs[index];
        if (input.optional && input.isInteger) {
          const inputvalue = this.finalItem[input.name];
          if (inputvalue != null) {
            validityState = validityState && this.numberRegex.test(inputvalue);
          }
        }
      }
      return validityState;
    },
    ...mapGetters(["itemInputs"])
  },
  created() {
    /**
     * Product always flat, it has every property without any nesting.
     */
    const item = this.itemInputs.reduce((acc, property) => {
      acc[property.name] = property.default
        ? property.default
        : property.type === "tags" || property.type === "multi-select"
        ? []
        : null;
      return acc;
    }, {});

    this.itemId = this.$getAtPath(["params", "itemId"], this.$route);

    if (this.itemId) {
      this.loading = true;
      this.$api
        .customRequest({
          method: "get",
          url: `/services/chatbot/pages/${this.$store.getters["page/id"]}/users/${this.$store.getters["user/id"]}/items/${this.itemId}`,
          responseType: "json"
        })
        .then(({ data }) => {
          this.item.id = this.itemId;
          this.item = Object.assign(item, data.item);
          this.previousCategories = JSON.parse(JSON.stringify(this.item.categories));
        })
        .catch(error => {
          console.log(error);
          this.$buefy.toast.open({
            duration: 3000,
            message: "حدث خطأ أثناء قراءة معلومات العنصر",
            position: "is-top",
            type: "is-danger"
          });
          // TODO show an error message
        })
        .finally(() => {
          this.loading = false;
          // TODO back to the products table
        });
    } else {
      // Initializing the item with the properties from itemId
      this.item = item;
    }
  },
  mounted() {
    const canStartTour = this.$store.getters["page/tours"]("item") == null;
    if (!this.itemId && canStartTour) {
      this.$tours["product_tour"].start();
    }
  },
  validations() {
    /**
     * make the required property TRUE if property.isRequired is true
     */
    const item = this.itemInputs.reduce((acc, property) => {
      acc[property.name] = {};
      if (property.isRequired) {
        acc[property.name].required = isRequired(property.type);
      }
      if (property.isInteger) {
        acc[property.name].integer = isInteger(
          property.isRequired && property.isInteger && this.item[property.name] != null
        ); // if the condition is false then there is no need to check if it is a Number, return true for this validation property
      }
      return acc;
    }, {});
    return { item };
  }
};
</script>

<style scoped lang="scss">
.product-form {
  overflow-x: hidden;
  text-align: right;
  min-height: calc(100vh - 50px);
  // height: 100%;
  .product-info,
  .variations-info {
    margin-top: 20px;
  }
  .taps-container {
    .m-tab-nav {
      flex-direction: row-reverse;
      .m-tab {
        &.selected {
          background: white;
          color: dodgerblue;
        }
      }
    }
  }
  #product-controll {
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    margin-bottom: 150px;
  }
}
</style>
