<template>
  <div style="margin-top: 50px;">
    <p style="font-weight: bold; font-size: 20px; ">
      {{ `:معاينة بطاقة ${this.$store.getters["recipesConfig/itemCardTitle"]}` }}
    </p>
    <div class="item-card" v-if="showCard">
      <div class="card-info">
        <div
          class="card-image"
          :style="`background-image: url(${image})`"
          :class="{ square: $store.getters['page/chatbotSettings'].aspectRatio === 'square' }"
        >
          <!-- <img v-if="image !== '' && image" :src="image" alt=""> -->
        </div>
        <div class="card-title">
          <p class="main-title">{{ name != null && name != "" ? name : "عنوان أساسي" }}</p>
          <!-- <p class="sub-title">{{ description != null && description != '' ? description : 'تفاصيل' }}</p> -->
        </div>
      </div>
      <div class="card-control">
        <div
          v-if="(description !== '' && description != null) || (price !== '' && price != null)"
          class="control-button"
        >
          تفاصيل
        </div>
        <div v-if="variations !== '' && variations != null" class="control-button">اختلافات</div>
        <div v-if="order !== '' && order != null" class="control-button">اطلب</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "item-card",
  props: {
    image: {
      type: String,
      default: ""
    },
    price: [String, Object],
    description: [String, Object],
    order: [String, Object], // this property must be read from the general options object.
    variations: [String, Object],
    name: [String, Object]
  },
  computed: {
    showCard() {
      return this.image && (this.description || this.price || this.variations || this.order);
    }
  }
};
</script>

<style lang="scss" scoped>
.item-card {
  width: 247px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.12), 0 2px 4px 0 rgba(0, 0, 0, 0.08);
  margin: auto;
  .card-info {
    .card-image {
      background-position: 50% 50%;
      // background-image: url("https://i.imgur.com/BdYpDAV.png");
      background-size: cover;
      min-height: 130px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      &.square {
        height: 247px;
      }
    }
    .card-title {
      padding: 0px 10px;
      .main-title {
        font-size: 16px;
        margin-bottom: 5px;
      }
      .sub-title {
        font-size: 13px;
        margin-bottom: 5px;
      }
    }
  }
  .card-control {
    background-color: #f0f0f0;
    padding: 15px;
    .control-button {
      cursor: pointer;
      display: flex;
      align-content: center;
      align-items: center;
      justify-content: center;
      height: 40px;
      text-align: center;
      background-color: #fbfbfb;
      margin: 7px;
      border-radius: 10px;
    }
  }
}
</style>
